/**
 * Base styles
 *
 */


*, *:before, *:after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'ArgestaDisplay-Regular';
  src: local('ArgestaDisplay-Regular'),
  url('../../assets/fonts/ArgestaDisplay-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SuisseIntl-Regular';
  src: local('SuisseIntl-Regular'),
  url('../../assets/fonts/SuisseIntl-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SuisseIntl-Medium';
  src: local('SuisseIntl-Medium'),
  url('../../assets/fonts/SuisseIntl-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntl-Bold';
  src: local('SuisseIntl-Bold'),
  url('../../assets/fonts/SuisseIntl-Bold.otf') format('opentype');
}

html, body {
  color: $project-color-black;
  font-family: 'ArgestaDisplay-Regular', sans-serif;
  font-size: $project-font-size-body;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
}

html, body, #app {
  display: flex;
  flex-direction: column;
}

body, #app {
  flex: 1 1 auto;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $project-color-app-bg;
  min-height: 100%;
}

body {
  overflow-y: auto;
  line-height: 1.5;
  background-color: transparent;
}

button {
  color: $project-color-text;
  cursor: pointer;
}

a {
  color: $project-color-text;
  cursor: pointer;
}

input {
  font-family: 'ArgestaDisplay-Regular', sans-serif;
}

svg {
  fill: currentColor;
}
